import React, { useEffect, useState } from 'react'

import { useFeatureFlag } from './useFeatureFlag'

interface FeatureFlagProps {
  featureKey: string
  children: React.ReactNode
}

export const FeatureFlag: React.FC<FeatureFlagProps> = ({
  featureKey,
  children,
}) => {
  const [isEnabled, setIsEnabled] = useState(false)
  const { featureEnabled } = useFeatureFlag()

  useEffect(() => {
    const checkFeatureFlag = async () => {
      const enabled = await featureEnabled(featureKey)
      setIsEnabled(enabled)
    }
    checkFeatureFlag()
  }, [featureKey, featureEnabled])

  if (!isEnabled) {
    return null
  }

  return <>{children}</>
}
