import { useCallback } from 'react'

import { useApolloClient, useLazyQuery } from '@apollo/client'
import { LoadPinnedNodes } from 'Features/PinnedNodes/usePinnedNodes'

import graphPeopleQuery from './Queries/graphPeople.graphql'
import graphPersonQuery from './Queries/graphPerson.graphql'
import LoadGraphTags, { ILoadGraphTagsArgs } from './LoadGraphTags'
import LoadRelationshipEdges from './LoadRelationshipEdges'
import { IGraphPeopleList, IGraphPersonNode } from './NodeTypes'

export const useGraphLoaders = () => {
  const client = useApolloClient()

  const [loadPeople, loadPeopleData] = useLazyQuery<
    Pick<MainSchema.Query, 'graphPeople'>,
    MainSchema.QueryGraphPeopleArgs
  >(graphPeopleQuery)

  const [loadPerson, loadPersonData] = useLazyQuery<
    Pick<MainSchema.Query, 'graphPerson'>,
    MainSchema.QueryGraphPersonArgs
  >(graphPersonQuery)

  const loadTags = useCallback(
    async (args: ILoadGraphTagsArgs) => {
      return LoadGraphTags({
        ...args,
        client,
      })
    },
    [client],
  )

  const loadRelationshipEdges = useCallback(
    async (args: ILoadGraphTagsArgs) => {
      return LoadRelationshipEdges({
        ...args,
        client,
      })
    },
    [client],
  )

  const loadPinnedNodes = useCallback(async () => {
    return LoadPinnedNodes({
      client,
    })
  }, [client])

  return {
    loadPeopleData: loadPeopleData.data?.graphPeople as IGraphPeopleList,
    loadPersonData: loadPersonData.data?.graphPerson as IGraphPersonNode,
    loadPeople,
    loadPerson,
    loadPinnedNodes,
    loadTags,
    loadRelationshipEdges,
  }
}
