import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { createBrowserHistory } from 'history'

import { createApolloClient } from 'Services/Apollo'

import graphPersonQuery from './Queries/graphPerson.graphql'

export interface IOnLoadPersonArgs {
  communityIds: string[]
  userId?: string
  communityUserId?: string
  showTargetSkills?: boolean
  showTargetTags?: boolean
  showTargetOrganizations?: boolean
  showTargetCommunities?: boolean
  showTargetEducation?: boolean
  excludeShowCommunities?: string[]
}

export async function loadPersonHandler({
  communityIds,
  userId,
  communityUserId,
}: IOnLoadPersonArgs): Promise<IGraphPersonNode | null> {
  if (!communityIds?.length) {
    return null
  }

  const history = createBrowserHistory()
  const client = createApolloClient({ history })

  const result = await client.query<
    Pick<MainSchema.Query, 'graphPerson'>,
    MainSchema.QueryGraphPersonArgs
  >({
    query: graphPersonQuery,
    variables: {
      communityIds,
      userId,
      communityUserId,
    },
  })

  return (result?.data?.graphPerson as IGraphPersonNode) || null
}
