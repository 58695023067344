import styled from 'styled-components'

import { IconPencil } from '@tabler/icons-react'

import {
  addNoteGlyph,
  addTagGlyph,
  addToCommunityGlyph,
  archiveUser,
  connectNodesGlyph,
  hideGlyph,
  introduceGlyph,
  pinToGraphGlyph,
  removePinGlyph,
  revealPathToConnectGlyph,
  userProfileGlyph,
  viewSelectedItemsGlyph,
} from 'Assets/Svg/icons/quickActions'

export const Profile = styled(userProfileGlyph)``
export const AddNoteIcon = styled(addNoteGlyph)``
export const AddTagIcon = styled(addTagGlyph)``
export const AddToCommunityIcon = styled(addToCommunityGlyph)``
export const ConnectNodesIcon = styled(connectNodesGlyph)``
export const IntroduceIcon = styled(introduceGlyph)``
export const RevealPathToConnectIcon = styled(revealPathToConnectGlyph)``
export const EditIcon = styled(IconPencil)``
export const ArchiveIcon = styled(archiveUser)``
export const HideIcon = styled(hideGlyph)``
export const ViewSelectedItemsIcon = styled(viewSelectedItemsGlyph)``
export const PinToGraphIcon = styled(pinToGraphGlyph)``
export const RemovePinIcon = styled(removePinGlyph)``
