import { StoreonModule } from 'storeon'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { getStore } from 'Services/Shared'

export const GRAPH_STATE_NAMESPACE = 'graph'

interface IGraphStateData {
  showPeopleConnections: boolean
  showTagConnections: boolean
  showSkillConnections: boolean
  showWorkHistoryConnections: boolean
  showEducationHistoryConnections: boolean
  showCommunityConnections: boolean
}

export interface GraphState {
  [GRAPH_STATE_NAMESPACE]: IGraphStateData
}

const GRAPH_STATE_DEFAULTS: GraphState[typeof GRAPH_STATE_NAMESPACE] = {
  showPeopleConnections: true,
  showTagConnections: true,
  showSkillConnections: true,
  showWorkHistoryConnections: true,
  showEducationHistoryConnections: true,
  showCommunityConnections: true,
}

enum GraphStateEvent {
  Set = `${GRAPH_STATE_NAMESPACE}/set`,
  Clear = `${GRAPH_STATE_NAMESPACE}/clear`,
}

export interface GraphStateEvents {
  [GraphStateEvent.Set]: any
  [GraphStateEvent.Clear]: undefined
}

export function getGraphStateApplicationStorage() {
  return get(getStore().get(), GRAPH_STATE_NAMESPACE)
}

export function setGraphStateApplicationStorage(
  state: GraphStateEvents[GraphStateEvent.Set],
) {
  return getStore().dispatch(GraphStateEvent.Set, state)
}

export function clearGraphStateApplicationStorage() {
  return getStore().dispatch(GraphStateEvent.Clear)
}

const graphStateModule: StoreonModule<GraphState, GraphStateEvents> = store => {
  store.on('@init', () => ({
    [GRAPH_STATE_NAMESPACE]: GRAPH_STATE_DEFAULTS,
  }))

  store.on(GraphStateEvent.Set, (state, nextCommunity) => {
    const currentCommunity = state[GRAPH_STATE_NAMESPACE]

    if (isEqual(nextCommunity, currentCommunity)) return null

    return { [GRAPH_STATE_NAMESPACE]: nextCommunity }
  })

  store.on(GraphStateEvent.Clear, () => ({
    [GRAPH_STATE_NAMESPACE]: GRAPH_STATE_DEFAULTS,
  }))
}

export default graphStateModule
