import { Chart, LinkStyle } from 'regraph'

import { theme } from 'Theme'

export enum GraphLayout {
  Organic = 'organic',
  Sequential = 'sequential',
  Structural = 'structural',
  Radial = 'radial',
  Lens = 'lens',
}

export const DEFAULT_LAYOUT: Chart.LayoutOptions = {
  name: GraphLayout.Organic,
  top: undefined,
  curvedLinks: false,
  tightness: 3,
}

export const DEFAULT_VIEW: Chart.ViewOptions = {
  zoom: 0,
  offsetX: 0,
  offsetY: 0,
}

export const DEFAULT_POSITIONS: Chart.Positions = {}

export const DUMMY_LINK: LinkStyle<any> = {
  color: theme.colors.primaryCardinal,
  width: 2,
}

export const CONTEXT_NETWORK_FIELD = {
  /* *** NETWORK *** */
  SHOW_NETWORK: 'showNetwork',
  FIRST_DEGREE: 'firstDegree',
  SECOND_DEGREE: 'secondDegree',
  THIRD_DEGREE: 'thirdDegree',
  ALL_TAGS: 'allTags',
  ALL_USERS: 'allUsers',
  /* *** LAYOUTS *** */
  LAYOUTS: 'layouts',
  ORGANIC_LAYOUT: 'organicLayout',
  SEQUENTIAL_LAYOUT: 'sequentialLayout',
  STRUCTURAL_LAYOUT: 'structuralLayout',
  RADIAL_LAYOUT: 'radialLayout',
  LENS_LAYOUT: 'lensLayout',
  /* *** RELATIONSHIP STRENGTH *** */
  RELATIONSHIP_STRENGTH: 'relationshipStrength',
  SHOW_STRONG: 'showStrong',
  SHOW_MODERATE: 'showModerate',
  SHOW_WEAK: 'showWeak',
  SHOW_NONE: 'showNone',
  /* *** RELATIONSHIP STRENGTH *** */
  NETWORK_ANALYZER: 'networkAnalyzer',
  NO_ANALYZER: 'noAnalyzer',
  BETWEENESS: 'betweenness',
  CLOSENESS: 'closeness',
  DEGREES: 'degrees',
  EIGEN_CENTRALITY: 'eigenCentrality',
  PAGE_RANK: 'pageRank',
  /* *** CLUSTERING *** */
  CLUSTER: 'cluster',
}

export const CONTEXT_RESET_FIELD = {
  /* *** RESET *** */
  RELAYOUT_GRAPH: 'relayoutGraph',
  RESET_GRAPH: 'resetGraph',
}

export const CONTEXT_GRAPH_FIELD = {
  /* *** GRAPH_CONTEXT *** */
  SELECTED_ITEMS: 'selectedItems',
  REMOVE_SELECTED: 'removeSelected',
}
