import { useCallback, useRef, useState } from 'react'

import { useCommunityContext, useGraphContext, useOnClickOutside } from 'Hooks'
import { IGraphState } from 'Hooks/useGraphContext'

import EventBus from 'Services/EventBus'

import { GraphLayout } from '../constants'
import { AnalyzerFunction } from '../utils'

export default function useControlHandlers() {
  const { community } = useCommunityContext()
  const contextNetworkMenuRef = useRef<HTMLDivElement | null>(null)
  const contextGraphSnapshotsMenuRef = useRef<HTMLDivElement | null>(null)
  const { graphHandler, graphQuery, graphMapper, setGraphState } =
    useGraphContext()

  const [showNetworkMenu, setShowNetworkMenu] = useState(false)
  const [showGraphSnapshotsMenu, setShowGraphSnapshotsMenu] = useState(false)

  const handleToggleBrowseUsers = useCallback((event: MouseEvent) => {
    EventBus.trigger(EventBus.actions.dashboard.browseUsers, event)
  }, [])

  const handleGraphOptions = useCallback(() => {
    // TODO: If we add it, create trigger to open it
  }, [])

  const handleResetGraph = useCallback(() => {
    graphMapper.handleGraphReset()
    setShowNetworkMenu(false)
    setGraphState((prevState: IGraphState) => ({
      ...prevState,
      appendUsers: [],
    }))
  }, [graphMapper, setGraphState])

  const handleLayoutGraph = useCallback(() => {
    graphHandler.handleLayoutGraph()
    setShowNetworkMenu(false)
  }, [graphHandler])

  // const handleCloseContextMenu = useCallback(() => {
  //   setShowNetworkMenu(false)
  //   setShowGraphSnapshotsMenu(false)
  // }, [])

  const handleShowNetwork = useCallback(
    (degrees: number) => {
      graphQuery.handleMyNetwork(degrees)
      setShowNetworkMenu(false)
    },
    [graphQuery],
  )

  const handleShowAllTags = useCallback(() => {
    graphQuery.handleLoadAllTags()
    setShowNetworkMenu(false)
  }, [graphQuery])

  const handleShowAllUsers = useCallback(() => {
    if (!community?.id) {
      return
    }
    graphQuery.handleLoadAllUsers([community.id])
    setShowNetworkMenu(false)
  }, [community?.id, graphQuery])

  const handleSetAnalyzer = useCallback(
    (name: AnalyzerFunction | null) => {
      graphMapper.handleChangeAnalyzer(name)
      setShowNetworkMenu(false)
    },
    [graphMapper],
  )

  const handleExitSubGraph = useCallback(() => {
    graphMapper.handleExitSubGraph()
  }, [graphMapper])

  useOnClickOutside(contextNetworkMenuRef, () => setShowNetworkMenu(false))
  useOnClickOutside(contextGraphSnapshotsMenuRef, () =>
    setShowGraphSnapshotsMenu(false),
  )

  const handleSetLayout = useCallback(
    (layout: GraphLayout) => {
      graphHandler.handleSetLayout(layout)
    },
    [graphHandler],
  )

  return {
    setShowNetworkMenu,
    setShowGraphSnapshotsMenu,
    showNetworkMenu,
    showGraphSnapshotsMenu,
    contextNetworkMenuRef,
    contextGraphSnapshotsMenuRef,
    handleToggleBrowseUsers,
    handleGraphOptions,
    handleExitSubGraph,
    handleResetGraph,
    handleLayoutGraph,
    handleShowNetwork,
    handleShowAllTags,
    handleShowAllUsers,
    handleSetAnalyzer,
    handleSetLayout,
  }
}
