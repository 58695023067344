import React, { useCallback, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation } from '@apollo/client'
import updateGraphSnapshotMutation from 'GraphQL/Mutations/GraphSnapshot/updateGraphSnapshot.graphql'
import graphSnapshotsQuery from 'GraphQL/Queries/GraphSnapshot/graphSnapshots.graphql'
import validate from 'validate.js'

import { Column, InputField, Modal } from 'Components/UI'

import { useCommunityContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

interface UpdateGraphSnapshotModalProps {
  graphSnapshot: MainSchema.GraphSnapshot
  isOpen?: boolean
  onClose?: (success: boolean) => void
}

enum UpdateGraphSnapshotFormField {
  Name = 'name',
}

interface UpdateGraphSnapshotFormValues {
  [UpdateGraphSnapshotFormField.Name]: string
}

const UpdateGraphSnapshotModal: React.FC<UpdateGraphSnapshotModalProps> = ({
  graphSnapshot,
  isOpen,
  onClose,
}) => {
  const { community } = useCommunityContext()
  const t = useScopedI18n('features.graphSnapshot')

  const updateGraphSnapshotFormConstraints: Record<
    UpdateGraphSnapshotFormField,
    any
  > = {
    [UpdateGraphSnapshotFormField.Name]: {
      type: 'string',
      presence: {
        allowEmpty: false,
        message: `^${t('name.validation.required')}`,
      },
      length: {
        maximum: 255,
        tooLong: `^${t('name.validation.tooLong')}`,
      },
    },
  }

  const initialValues: UpdateGraphSnapshotFormValues = {
    [UpdateGraphSnapshotFormField.Name]: graphSnapshot.name,
  }

  const [updateGraphSnapshot] = useMutation<
    Pick<MainSchema.Mutation, 'updateGraphSnapshot'>,
    MainSchema.MutationUpdateGraphSnapshotArgs
  >(updateGraphSnapshotMutation)

  const [isLoading, setIsLoading] = useState(false)

  const submit = useCallback(
    async (values: UpdateGraphSnapshotFormValues) => {
      if (!community) {
        throw new Error('community is not set')
      }

      setIsLoading(true)

      try {
        await updateGraphSnapshot({
          variables: {
            id: graphSnapshot.id,
            name: values[UpdateGraphSnapshotFormField.Name],
          },
          refetchQueries: [
            {
              query: graphSnapshotsQuery,
              variables: {
                communityId: community.id,
              },
            },
          ],
        })

        toast.success({
          title: t('toast.title'),
          text: t('toast.updated'),
        })

        onClose?.(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [community, updateGraphSnapshot, onClose, t, graphSnapshot.id],
  )

  const renderForm = useCallback(
    ({
      handleSubmit,
      valid: isValid,
    }: FormRenderProps<UpdateGraphSnapshotFormValues>) => {
      return (
        <Modal
          cancelText={t('form.cancel')}
          confirmDisabled={isLoading || !isValid}
          confirmText={t('form.update')}
          isOpen={isOpen}
          title={t('modal.updateGraphSnapshotModal.title')}
          width="456px"
          onClose={() => onClose?.(true)}
          onConfirm={handleSubmit}
        >
          <Column gap={3}>
            <InputField
              checkErrorIfDirty
              label={t('form.name.label')}
              name={UpdateGraphSnapshotFormField.Name}
              required
            />
          </Column>
        </Modal>
      )
    },
    [isLoading, isOpen, onClose, t],
  )

  if (!isOpen) return null

  return (
    <Form<UpdateGraphSnapshotFormValues>
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, updateGraphSnapshotFormConstraints)}
      onSubmit={submit}
    />
  )
}

export default UpdateGraphSnapshotModal
