import { IGraphPeopleList } from 'Features/GraphNodes/NodeTypes'
import { createBrowserHistory } from 'history'

import { createApolloClient } from 'Services/Apollo'

import graphPeopleQuery from './Queries/graphPeople.graphql'

export interface IOnLoadPeopleArgs {
  communityIds: string[]
  userIds?: string[]
  skillIds?: string[]
  tagIds?: string[]
  workHistoryOrganizationIds?: string[]
  workHistoryJobTitleIds?: string[]
  educationHistoryOrganizationIds?: string[]
  limit?: number
  page?: number
  orderBy?: MainSchema.GraphQueryOrderByInput
}

export async function loadPeopleHandler({
  communityIds,
  userIds,
  skillIds,
  tagIds,
  workHistoryOrganizationIds,
  workHistoryJobTitleIds,
  educationHistoryOrganizationIds,
  limit = 25,
  page = 0,
  orderBy = { order: 'createdAt', direction: 'desc' },
}: IOnLoadPeopleArgs): Promise<IGraphPeopleList> {
  if (!communityIds?.length) {
    return {
      nodes: [],
      pages: 0,
      count: 0,
    }
  }

  const history = createBrowserHistory()
  const client = createApolloClient({ history })

  const result = await client.query<
    Pick<MainSchema.Query, 'graphPeople'>,
    MainSchema.QueryGraphPeopleArgs
  >({
    query: graphPeopleQuery,
    variables: {
      communityIds,
      userIds,
      skillIds,
      tagIds,
      workHistoryOrganizationIds,
      workHistoryJobTitleIds,
      educationHistoryOrganizationIds,
      limit,
      page,
      orderBy,
    },
  })

  return (
    (result?.data?.graphPeople as IGraphPeopleList) || {
      nodes: [],
      pages: 0,
      count: 0,
    }
  )
}
