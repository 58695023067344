import { USER_ROLE } from 'Constants/ids'

import { COMMUNITY, ROOT } from 'Router/routes'

export function getRootRoute(user?: MainSchema.User) {
  switch (user?.role) {
    case USER_ROLE.USER:
    case USER_ROLE.SUPER_ADMIN:
      return COMMUNITY
    default:
      return ROOT
  }
}
