import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { createBrowserHistory } from 'history'
import PQueue from 'p-queue'
import { delay } from 'Utils/Promises'

import random from 'lodash/random'

import { createApolloClient } from 'Services/Apollo'

import graphPeopleQuery from './Queries/graphPeople.graphql'

export async function* streamPeopleHandler(
  communityIds: string[],
  limit: number = 250,
): AsyncGenerator<void | IGraphPersonNode[], void, unknown> {
  if (!communityIds?.length) {
    return
  }

  const history = createBrowserHistory()
  const client = createApolloClient({ history })

  const result = await client.query<
    Pick<MainSchema.Query, 'graphPeople'>,
    MainSchema.QueryGraphPeopleArgs
  >({
    query: graphPeopleQuery,
    fetchPolicy: 'network-only',
    variables: {
      communityIds,
      limit,
      page: 0,
    },
  })

  const people = (result?.data?.graphPeople?.nodes as IGraphPersonNode[]) || []
  yield people

  if (result?.data?.graphPeople?.pages) {
    const requestQueue = new PQueue({ concurrency: 3 })

    const results: Promise<void | IGraphPersonNode[]>[] = []
    for (
      let currentPage = 1;
      currentPage <= result?.data?.graphPeople?.pages;
      currentPage += 1
    ) {
      const task = requestQueue.add(async () => {
        // Add a delay to give the UI time to refresh
        // Try not to have each thread fire at same exact time
        await delay(random(100, 250))
        const result = await client.query<
          Pick<MainSchema.Query, 'graphPeople'>,
          MainSchema.QueryGraphPeopleArgs
        >({
          query: graphPeopleQuery,
          variables: {
            communityIds,
            limit,
            page: currentPage,
          },
        })
        const people =
          (result?.data?.graphPeople?.nodes as IGraphPersonNode[]) || []
        return people
      })
      results.push(task)
    }

    // Wait for all requests to complete and yield results as they come in
    for (const result of results) {
      const people = await result
      yield people
    }
  }
}
