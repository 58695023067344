import React from 'react'

import Regraph, { Chart } from 'regraph'

import forEach from 'lodash/forEach'

import { KEYS } from 'Constants/ids'

import { useWindowDimensions } from 'Hooks'
import useAppContext from 'Hooks/useAppContext'

import { getGraphStateApplicationStorage } from 'Services/Store/graph'

import { IGraphEdge } from './Edges/BaseGraphEdge'
import { analyzeNodes, AnalyzerFunction } from './Helpers/AnalyzersFunction'
import { IGraphNode } from './Nodes/BaseGraphNode'
import {
  COMBO_PROPERTIES,
  DEFAULT_DATA,
  DEFAULT_LAYOUT,
  DEFAULT_OPTIONS,
  GRAPH_ANIMATION_TIME,
} from './constants'
import {
  IAvailableQuickAction,
  IData,
  IRegraphContext,
  IRegraphProviderProps,
  ISubGraph,
  Panels,
  QuickActionBarCoordinates,
} from './IRegraphContext'

const RegraphContext = React.createContext<IRegraphContext | undefined>(
  undefined,
)

export const RegraphContextProvider: React.FC<IRegraphProviderProps> = ({
  children,
  targetUserId,
}) => {
  const { me } = useAppContext()
  // App Storage of Graph Settings (show edges)
  const graphStateStorage = getGraphStateApplicationStorage()
  // Window State
  const [windowDimension] = useWindowDimensions()
  const [loading, setLoading] = React.useState(false)

  // Graph Component State and options
  const [options, setOptions] = React.useState(DEFAULT_OPTIONS)
  const [animation, setAnimation] = React.useState<any>({
    animate: true,
    time: GRAPH_ANIMATION_TIME,
  })
  const [positions, setPositions] = React.useState<Chart.Positions>({})
  const [selection, setSelection] = React.useState<Regraph.Items>({})
  const [contextMenuPosition, setContextMenuPosition] = React.useState<
    any | null
  >(null)
  const [availableQuickActions, setAvailableQuickActions] = React.useState<
    Record<string, Record<string, IAvailableQuickAction>>
  >({})
  const [keysDown, setKeysDown] = React.useState<string[]>([])
  const [layout, setLayout] = React.useState<Chart.LayoutOptions>({
    ...DEFAULT_LAYOUT,
    top: targetUserId || me?.userId || undefined,
  })
  const [viewOptions, setViewOptions] = React.useState<Chart.ViewOptions>()
  const [combine, setCombine] = React.useState<Chart.CombineOptions>({
    properties: COMBO_PROPERTIES,
    level: 0,
  })

  const [currentAnalyzer, setCurrentAnalyzer] =
    React.useState<AnalyzerFunction | null>(null)

  // Edge Selection
  const [showPeopleConnections, setShowPeopleConnections] =
    React.useState(false)
  const [showTagConnections, setShowTagConnections] = React.useState(false)
  const [showSkillConnections, setShowSkillConnections] = React.useState(false)
  const [showWorkHistoryConnections, setShowWorkHistoryConnections] =
    React.useState(false)
  const [showEducationHistoryConnections, setShowEducationHistoryConnections] =
    React.useState(false)
  const [showCommunityConnections, setShowCommunityConnections] =
    React.useState(false)

  const [isComboOpen, setIsComboOpen] = React.useState<Record<string, boolean>>(
    {},
  )
  const [quickActionsCoordinates, setQuickActionsCoordinates] =
    React.useState<QuickActionBarCoordinates | null>(null)

  const [activePanel, setActivePanel] = React.useState<Panels | null>(null)

  const [contextMenuIds, setContextMenuIds] = React.useState<string[]>([])

  // Refs
  const [chartRef, setChartRef] = React.useState<Chart | null>(null)
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(
    null,
  )

  // Raw Graph Data
  const [data, setData] = React.useState<IData>(DEFAULT_DATA)

  const [subGraph, setSubGraph] = React.useState<ISubGraph[]>([])

  // Highlighted Edges
  const [highlightEdges, setHighlightEdges] = React.useState<Regraph.Items>({})

  // Rendered Items
  const [items, setItems] = React.useState<Regraph.Items>({})

  // Edge Cache / Lookup Data
  const cachedAllEdges = React.useMemo(() => {
    return Object.fromEntries(
      Object.entries({
        ...data?.allEdges,
      }).map(([key, item]) => [key, { ...item.item }]),
    ) as Regraph.Items
  }, [data?.allEdges])

  // Our final combined exported content
  const context = React.useMemo(
    () =>
      ({
        animation,
        windowDimension,
        viewOptions,
        loading,
        data,
        items,
        subGraph,
        cachedAllEdges,
        highlightEdges,
        combine,
        isComboOpen,
        positions,
        selection,
        contextMenuPosition,
        keysDown,
        layout,
        options,
        quickActionsCoordinates,
        activePanel,
        contextMenuIds,
        chartRef,
        containerRef,
        currentAnalyzer,
        availableQuickActions,
        showPeopleConnections,
        showTagConnections,
        showSkillConnections,
        showWorkHistoryConnections,
        showEducationHistoryConnections,
        showCommunityConnections,
        // State Handlers
        setAnimation,
        setLoading,
        setOptions,
        setShowPeopleConnections,
        setShowTagConnections,
        setShowSkillConnections,
        setShowWorkHistoryConnections,
        setShowEducationHistoryConnections,
        setShowCommunityConnections,
        setData,
        setSubGraph,
        setSelection,
        setContextMenuPosition,
        setPositions,
        setKeysDown,
        setHighlightEdges,
        setCombine,
        setIsComboOpen,
        setQuickActionsCoordinates,
        setActivePanel,
        setContextMenuIds,
        setViewOptions,
        setLayout,
        setChartRef,
        setContainerRef,
        setAvailableQuickActions,
        setCurrentAnalyzer,
      }) as IRegraphContext,
    [
      animation,
      windowDimension,
      viewOptions,
      loading,
      data,
      items,
      subGraph,
      cachedAllEdges,
      highlightEdges,
      combine,
      isComboOpen,
      positions,
      selection,
      contextMenuPosition,
      keysDown,
      layout,
      options,
      quickActionsCoordinates,
      activePanel,
      contextMenuIds,
      chartRef,
      containerRef,
      currentAnalyzer,
      availableQuickActions,
      showPeopleConnections,
      showTagConnections,
      showSkillConnections,
      showWorkHistoryConnections,
      showEducationHistoryConnections,
      showCommunityConnections,
    ],
  )

  // TODO: Temporary until I finalize the edge cache structure, see above TODO regarding using keys for faster lookup
  // I'd want to move this out of a useEffect and into a more direct call when a node is added/removed
  React.useEffect(() => {
    const newConnectionEdges: Record<string, IGraphEdge> = {}
    const newTagEdges: Record<string, IGraphEdge> = {}
    const newSkillEdges: Record<string, IGraphEdge> = {}
    const newOrganizationEdges: Record<string, IGraphEdge> = {}
    const newCommunityEdges: Record<string, IGraphEdge> = {}
    const newEducationEdges: Record<string, IGraphEdge> = {}

    forEach(data.allEdges, (edge: IGraphEdge) => {
      if (
        edge?.item &&
        data?.people[edge.item.id1] &&
        data?.people[edge.item.id2]
      ) {
        newConnectionEdges[edge?.id] = edge
      } else if (
        edge?.item &&
        (data?.tags[edge.item.id1] || data?.tags[edge.item.id2])
      ) {
        newTagEdges[edge?.id] = edge
      } else if (
        edge?.item &&
        (data?.skills[edge.item.id1] || data?.skills[edge.item.id2])
      ) {
        newSkillEdges[edge?.id] = edge
      } else if (
        edge?.item &&
        (data?.organizations[edge.item.id1] ||
          data?.organizations[edge.item.id2])
      ) {
        newOrganizationEdges[edge?.id] = edge
      } else if (
        edge?.item &&
        (data?.communities[edge.item.id1] || data?.communities[edge.item.id2])
      ) {
        newCommunityEdges[edge?.id] = edge
      }
    })
    setData(prevState => ({
      ...prevState,
      peopleEdges: {
        ...prevState.peopleEdges,
        ...newConnectionEdges,
      },
      tagEdges: {
        ...prevState.tagEdges,
        ...newTagEdges,
      },
      skillEdges: {
        ...prevState.skillEdges,
        ...newSkillEdges,
      },
      workHistoryEdges: {
        ...prevState.workHistoryEdges,
        ...newOrganizationEdges,
      },
      educationHistoryEdges: {
        ...prevState.educationHistoryEdges,
        ...newEducationEdges,
      },
      communityEdges: {
        ...prevState.communityEdges,
        ...newCommunityEdges,
      },
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Only trigger if nodes change, not if the entire data object changes.
    data.people,
    data.tags,
    data.skills,
    data.organizations,
    data.communities,
  ])

  React.useEffect(() => {
    setShowPeopleConnections(graphStateStorage?.showPeopleConnections ?? true)
    setShowTagConnections(graphStateStorage?.showTagConnections ?? true)
    setShowSkillConnections(graphStateStorage?.showSkillConnections ?? true)
    setShowWorkHistoryConnections(
      graphStateStorage?.showWorkHistoryConnections ?? true,
    )
    setShowEducationHistoryConnections(
      graphStateStorage?.showEducationHistoryConnections ?? true,
    )
    setShowCommunityConnections(
      graphStateStorage?.showCommunityConnections ?? true,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const isHandMode =
      !keysDown.includes(KEYS.META) &&
      !keysDown.includes(KEYS.CONTROL) &&
      !keysDown.includes(KEYS.SHIFT)
    setOptions(prevState => ({
      ...prevState,
      handMode: isHandMode,
    }))
  }, [keysDown])

  // Our final combined items object
  React.useEffect(() => {
    async function processNewItems() {
      // Build new items object
      const newItems: Record<string, IGraphNode | IGraphEdge> = {
        ...data.people,
        ...data.tags,
        ...data.skills,
        ...data.organizations,
        ...data.communities,
        ...(showTagConnections ? data.tagEdges : {}),
        ...(showPeopleConnections ? data.peopleEdges : {}),
        ...(showSkillConnections ? data.skillEdges : {}),
        ...(showWorkHistoryConnections ? data.workHistoryEdges : {}),
        ...(showEducationHistoryConnections ? data.educationHistoryEdges : {}),
        ...(showCommunityConnections ? data.communityEdges : {}),
      } as Record<string, IGraphNode | IGraphEdge>

      const mergedItems = Object.fromEntries(
        Object.entries(newItems || {}).map(([key, item]) => [key, item.item]),
      ) as Regraph.Items

      // Analyze
      const updatedItems: Regraph.Items =
        currentAnalyzer !== null
          ? await analyzeNodes(currentAnalyzer, {
              ...mergedItems,
              // ...pathingItems,
            })
          : { ...mergedItems } // ...pathingItems }
      // Add Paths

      // Etc

      // Merge with prebuilt items, like highlighted edges
      setItems({ ...updatedItems, ...highlightEdges })
    }
    processNewItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.people,
    data.tags,
    data.skills,
    data.organizations,
    data.communities,
    data.tagEdges,
    data.peopleEdges,
    data.skillEdges,
    data.workHistoryEdges,
    data.educationHistoryEdges,
    data.communityEdges,
    subGraph,
    showTagConnections,
    showPeopleConnections,
    showSkillConnections,
    showWorkHistoryConnections,
    showEducationHistoryConnections,
    showCommunityConnections,
    currentAnalyzer,
    highlightEdges,
  ])

  return (
    <RegraphContext.Provider value={context}>
      {children}
    </RegraphContext.Provider>
  )
}

export function useRegraphContext(): IRegraphContext {
  return React.useContext(RegraphContext) as IRegraphContext
}
