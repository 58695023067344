import {
  betweenness,
  closeness,
  degrees,
  eigenCentrality,
  pageRank,
} from 'regraph/analysis'

import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'

export type AnalyzerFunction = keyof typeof analyzerFunctions

export const analyzerFunctions: Record<string, CallableFunction> = {
  betweenness,
  closeness,
  degrees,
  eigenCentrality,
  pageRank,
}

export async function analyzeNodes(analyzer: AnalyzerFunction, nodes: any) {
  const res = await analyzerFunctions[analyzer](nodes, {})

  const nodesCopy = cloneDeep(nodes)

  forEach(res, (value, key) => {
    nodesCopy[key].size = Math.max(Math.min(Number(value), 8), 0.5)
  })

  return nodesCopy
}
