import React, { useEffect, useMemo, useState } from 'react'

import intersection from 'lodash/intersection'
import intersectionBy from 'lodash/intersectionBy'

import { Tooltip } from 'Components/UI'

import { QUICK_ACTION_KIND } from 'Constants/graph'

import useGraphContext, { IAvailableQuickAction } from 'Hooks/useGraphContext'

import * as Styled from './QuickActionBar.styles'
import QuickActionMenu from './QuickActionMenu'
import QuickActionMenuGroup from './QuickActionMenuGroup'
import QuickActionMenuOption from './QuickActionMenuOption'

import { GRAPH_QUICK_ACTION_KINDS } from '../../QuickActions/constants'
import utils, { IQuickAction } from '../utils'

export interface IQuickActionMenuGroup {
  heading?: string
  options: IQuickActionMenuOption[]
}

interface IQuickActionMenuOption {
  id: string
  label: string
  icon: React.ReactNode
  handleClick?: () => void
  groups?: IQuickActionMenuGroup[]
}

export const CONTEXT_MENU_ID = 'more'

// TODO: figure out a better method of rendering this menu
// TODO: add support for sub menus
const buildQuickActionBarOptions = (quickActions: IQuickAction[]) => {
  const options: IQuickActionMenuOption[] = []

  const editQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.EDIT,
  )
  const viewProfileQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.VIEW_PROFILE,
  )
  const createNoteQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.CREATE_NOTE,
  )
  const addSkillsTagsQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.ADD_SKILLS_TAGS,
  )
  const viewSelectedItemsQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.VIEW_SELECTED_ITEMS,
  )
  const hideQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.HIDE,
  )
  const pathToUserQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.PATH_TO_USER,
  )
  const introduceQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.INTRODUCE_TO,
  )
  const addToCommunityQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.ADD_TO_COMMUNITY,
  )
  const archiveQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.ARCHIVE,
  )
  const pinToGraphQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.PIN_TO_GRAPH,
  )
  const removePinQuickAction = quickActions.find(
    quickAction => quickAction.kind === QUICK_ACTION_KIND.REMOVE_PIN,
  )

  if (viewProfileQuickAction) {
    options.push({
      id: viewProfileQuickAction.kind,
      label: viewProfileQuickAction.label,
      icon: viewProfileQuickAction.icon,
      handleClick: viewProfileQuickAction.handleClick,
    })
  }

  if (createNoteQuickAction) {
    options.push({
      id: createNoteQuickAction.kind,
      label: createNoteQuickAction.label,
      icon: createNoteQuickAction.icon,
      handleClick: createNoteQuickAction.handleClick,
    })
  }

  if (addSkillsTagsQuickAction) {
    options.push({
      id: addSkillsTagsQuickAction.kind,
      label: addSkillsTagsQuickAction.label,
      icon: addSkillsTagsQuickAction.icon,
      handleClick: addSkillsTagsQuickAction.handleClick,
    })
  }

  const moreGroups: IQuickActionMenuGroup[] = []
  const moreGroup1Options: IQuickActionMenuOption[] = []
  const moreGroup2Options: IQuickActionMenuOption[] = []
  const moreGroup3Options: IQuickActionMenuOption[] = []

  if (pinToGraphQuickAction) {
    moreGroup1Options.push({
      id: pinToGraphQuickAction.kind,
      label: pinToGraphQuickAction.label,
      icon: pinToGraphQuickAction.icon,
      handleClick: pinToGraphQuickAction.handleClick,
    })
  }

  if (removePinQuickAction) {
    moreGroup1Options.push({
      id: removePinQuickAction.kind,
      label: removePinQuickAction.label,
      icon: removePinQuickAction.icon,
      handleClick: removePinQuickAction.handleClick,
    })
  }

  if (hideQuickAction) {
    moreGroup1Options.push({
      id: hideQuickAction.kind,
      label: hideQuickAction.label,
      icon: hideQuickAction.icon,
      handleClick: hideQuickAction.handleClick,
    })
  }

  if (viewSelectedItemsQuickAction) {
    moreGroup2Options.push({
      id: viewSelectedItemsQuickAction.kind,
      label: viewSelectedItemsQuickAction.label,
      icon: viewSelectedItemsQuickAction.icon,
      handleClick: viewSelectedItemsQuickAction.handleClick,
    })
  }

  if (pathToUserQuickAction) {
    moreGroup2Options.push({
      id: pathToUserQuickAction.kind,
      label: pathToUserQuickAction.label,
      icon: pathToUserQuickAction.icon,
      handleClick: pathToUserQuickAction.handleClick,
    })
  }

  if (introduceQuickAction) {
    moreGroup2Options.push({
      id: introduceQuickAction.kind,
      label: introduceQuickAction.label,
      icon: introduceQuickAction.icon,
      handleClick: introduceQuickAction.handleClick,
    })
  }

  if (addToCommunityQuickAction) {
    moreGroup2Options.push({
      id: addToCommunityQuickAction.kind,
      label: addToCommunityQuickAction.label,
      icon: addToCommunityQuickAction.icon,
      handleClick: addToCommunityQuickAction.handleClick,
    })
  }

  if (editQuickAction) {
    moreGroup3Options.push({
      id: editQuickAction.kind,
      label: editQuickAction.label,
      icon: editQuickAction.icon,
      handleClick: editQuickAction.handleClick,
    })
  }

  if (archiveQuickAction) {
    moreGroup3Options.push({
      id: archiveQuickAction.kind,
      label: archiveQuickAction.label,
      icon: archiveQuickAction.icon,
      handleClick: archiveQuickAction.handleClick,
    })
  }

  if (moreGroup1Options.length > 0) {
    moreGroups.push({
      options: moreGroup1Options,
    })
  }

  if (moreGroup2Options.length > 0) {
    moreGroups.push({
      options: moreGroup2Options,
    })
  }

  if (moreGroup3Options.length > 0) {
    moreGroups.push({
      options: moreGroup3Options,
    })
  }

  if (moreGroups.length > 0) {
    options.push({
      id: CONTEXT_MENU_ID,
      // TODO: move to translations
      label: 'More',
      icon: <Styled.DotsIcon />,
      groups: moreGroups,
    })
  }

  return options
}

export interface QuickActionBarCoordinates {
  x: number
  y: number
}

export interface QuickActionBarProps {
  availableQuickActions: Record<string, Record<string, IAvailableQuickAction>>
  coordinates: QuickActionBarCoordinates
}

const QuickActionBar = (props: QuickActionBarProps) => {
  // TODO: refactor the activeMenuIds and showContextMenuIds so that they are combined into a single state
  const { showContextMenuIds, setShowContextMenuIds } = useGraphContext()
  const [activeMenuIds, setActiveMenuIds] = useState<string[]>([])

  const ids = useMemo(
    () => Object.keys(props.availableQuickActions),
    [props.availableQuickActions],
  )

  // find all enabled quick actions for each user
  const enabledQuickActions: IQuickAction[][] = []
  Object.values(props.availableQuickActions).forEach(
    availableUserQuickActions => {
      const userQuickActions = utils.getEnabledQuickActions(
        GRAPH_QUICK_ACTION_KINDS,
        availableUserQuickActions,
      )

      enabledQuickActions.push(userQuickActions)
    },
  )

  // find all quick actions that each user has in common
  const quickActions = intersectionBy(...enabledQuickActions, 'kind')

  const menuItems = buildQuickActionBarOptions(quickActions)

  useEffect(() => {
    if (quickActions.length === 0) {
      setActiveMenuIds([])
      setShowContextMenuIds([])
    }
  }, [quickActions.length, setShowContextMenuIds])

  useEffect(() => {
    setActiveMenuIds(
      intersection(ids, showContextMenuIds).length > 0 ? [CONTEXT_MENU_ID] : [],
    )
  }, [ids, showContextMenuIds])

  if (quickActions.length === 0) {
    return null
  }

  return (
    <Styled.Container left={props.coordinates.x} top={props.coordinates.y}>
      <Styled.Content>
        {menuItems.map(menuItem => {
          const isOpen = activeMenuIds.includes(menuItem.id)

          return (
            <Styled.ButtonContainer key={menuItem.id}>
              <QuickActionMenu
                isOpen={isOpen}
                placement="bottom-start"
                trigger={
                  <Tooltip content={menuItem.label} placement="bottom">
                    <Styled.Button
                      isActive={isOpen}
                      type="button"
                      onClick={() => {
                        menuItem.handleClick?.()

                        // TODO: come up with a better fix for this
                        setActiveMenuIds(!isOpen ? [menuItem.id] : [])
                        if (menuItem.id === CONTEXT_MENU_ID) {
                          setShowContextMenuIds(!isOpen ? ids : [])
                        }
                      }}
                    >
                      {menuItem.icon}
                    </Styled.Button>
                  </Tooltip>
                }
              >
                {menuItem.groups?.map((menuItemGroup, index) => {
                  return (
                    <QuickActionMenuGroup
                      heading={menuItemGroup.heading}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      {menuItemGroup.options.map(menuItemGroupOption => {
                        return (
                          <QuickActionMenuOption
                            icon={menuItemGroupOption.icon}
                            key={menuItemGroupOption.id}
                            label={menuItemGroupOption.label}
                            onClick={() => {
                              menuItemGroupOption.handleClick?.()
                              setActiveMenuIds([])
                              setShowContextMenuIds([])
                            }}
                          />
                        )
                      })}
                    </QuickActionMenuGroup>
                  )
                })}
              </QuickActionMenu>
            </Styled.ButtonContainer>
          )
        })}
      </Styled.Content>
    </Styled.Container>
  )
}

export default QuickActionBar
