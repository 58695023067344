import React, {
  ChangeEvent,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'

import { MdCheck } from 'react-icons/md'

import * as icons from 'Assets/Svg/icons'

import Switch from 'Components/UI/Form/Switch/Switch'

import { useScopedI18n } from 'Services/I18n'

import * as Styled from './styles'

import Tooltip from '../Tooltip'

export interface IMenuItem extends PropsWithChildren {
  id: string
  label: string
  isSelected?: boolean
  useToggle?: boolean
  isToggled?: boolean
  isDefault?: boolean
  canBeFavorited?: boolean
  onFavorited?: MouseEventHandler
  areChildrenScrollable?: boolean
  showDivider?: boolean
  isDisabled?: boolean
  iconPrefix?: Styled.IconType
  onClick?: (
    event: MouseEvent<HTMLDivElement> | ChangeEvent<Element>,
    id: string,
  ) => void
}

function MenuItem({
  children,
  label,
  id,
  isSelected = false,
  useToggle = false,
  isToggled = false,
  isDefault = false,
  canBeFavorited = false,
  onFavorited = () => {},
  showDivider = false,
  isDisabled = false,
  iconPrefix,
  areChildrenScrollable,
  onClick,
}: IMenuItem) {
  const itemRef = useRef<HTMLDivElement>(null)
  const [isHovered, setIsHovered] = useState(false)

  const [showMenuUnfoldLeft, setShowMenuUnfoldLeft] = useState(false)

  const t = useScopedI18n('components.ui.contextMenu.menuItem')

  useEffect(() => {
    const checkPosition = () => {
      if (itemRef.current) {
        const rect = itemRef.current.getBoundingClientRect()
        const windowWidth = window.innerWidth
        const threshold = 200
        setShowMenuUnfoldLeft(windowWidth - rect.right < threshold)
      }
    }

    checkPosition()
    window.addEventListener('resize', checkPosition)
    return () => window.removeEventListener('resize', checkPosition)
  }, [])

  const renderIconPrefix = () => {
    if (isSelected) {
      return <MdCheck size={14} />
    }
    if (canBeFavorited) {
      return <div style={{ width: 14 }} />
    }
    if (iconPrefix) {
      const IconComponent = icons[iconPrefix as Styled.IconType]
      return (
        <Styled.StyledIcon>
          <IconComponent />
        </Styled.StyledIcon>
      )
    }

    return null
  }

  const renderIconSuffix = () => {
    if (!useToggle) {
      if (React.Children.count(children) === 0 && canBeFavorited) {
        let iconType
        let iconMessage
        switch (true) {
          case isDefault:
            iconType = 'starFilled'
            iconMessage = t('defaultView')
            break
          case isHovered:
            iconType = 'starOutline'
            iconMessage = t('setDefaultView')
            break
          default:
            return null
        }
        const IconComponent = icons[iconType as Styled.IconType]
        return (
          <Tooltip content={iconMessage} placement="right">
            <Styled.StyledIcon onClick={onFavorited}>
              <IconComponent />
            </Styled.StyledIcon>
          </Tooltip>
        )
      }
      if (React.Children.count(children) > 0) {
        return <Styled.ArrowForwardIcon />
      }
    } else {
      return (
        <Switch
          checked={isToggled}
          marginLeft="auto"
          onChange={event => onClick?.(event, id)}
        />
      )
    }
    return null
  }

  return (
    <Styled.Item
      aria-disabled={isDisabled}
      isSelected={isSelected}
      ref={itemRef}
      showDivider={showDivider}
      onMouseEnter={() => !isDisabled && setIsHovered(true)}
      onMouseLeave={() => !isDisabled && setIsHovered(false)}
    >
      {renderIconPrefix()}

      <Styled.ItemLabel onClick={event => !isDisabled && onClick?.(event, id)}>
        {label}
      </Styled.ItemLabel>

      {renderIconSuffix()}

      {isHovered && React.Children.count(children) > 0 && (
        <Styled.Menu
          hovered
          menuLeft={showMenuUnfoldLeft}
          scrollable={areChildrenScrollable}
        >
          {children}
        </Styled.Menu>
      )}
    </Styled.Item>
  )
}

export function MenuItemHeader({ children }: React.PropsWithChildren) {
  return (
    <Styled.ItemHeader>
      <div style={{ width: 14 }} />
      {children}
    </Styled.ItemHeader>
  )
}

export default MenuItem
