import React, { useCallback, useMemo } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import { firstInitialOrFallback } from 'Features/CommunityUsers/communityUserNameUtil'
import finalizeOnboardingMutation from 'GraphQL/Mutations/Onboarding/finalizeOnboarding.graphql'
import validate from 'validate.js'

import { ProfileField } from 'Components/Blocks/OwnProfile/constants'
import { AvatarInputField, Button, InputField } from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import { IProfileValues } from 'Pages/Profile/Profile'

import * as ROUTES from 'Router/routes'

import _ from 'Services/I18n'

import { Action, Profile } from './styles'

export default function Step1() {
  const { me, refetchMe } = useAppContext()
  const navigate = useNavigate()

  const firstNameInitial = firstInitialOrFallback({
    ...me,
    ...me?.profile,
  } as MainSchema.CommunityUser)
  const [finalizeOnboarding] = useMutation(finalizeOnboardingMutation)
  const initialValues = useMemo(() => {
    return {
      [ProfileField.PhotoUrl]: me?.profile?.photoUrl,
      [ProfileField.FirstName]: me?.profile?.firstName,
      [ProfileField.LastName]: me?.profile?.lastName,
      [ProfileField.About]: me?.profile?.about,
      [ProfileField.LinkedInUrl]: me?.profile?.linkedIn,
    }
  }, [
    me?.profile?.about,
    me?.profile?.firstName,
    me?.profile?.lastName,
    me?.profile?.linkedIn,
    me?.profile?.photoUrl,
  ])
  const formConstraints = useMemo(
    () => ({
      [ProfileField.FirstName]: {
        presence: {
          presence: true,
          message: `^First name is required`,
        },
        length: {
          maximum: 255,
          tooLong: `^First name is too long`,
        },
      },
      [ProfileField.LastName]: {
        presence: {
          presence: true,
          message: `^Last name is required`,
        },
        length: {
          maximum: 255,
          tooLong: `^Last name is too long`,
        },
      },
      [ProfileField.Organization]: {
        length: {
          maximum: 255,
          tooLong: `^Company is too long`,
        },
      },
      [ProfileField.JobTitle]: {
        length: {
          maximum: 255,
          tooLong: `^Title is too long`,
        },
      },
      [ProfileField.About]: {
        length: {
          maximum: 255,
          tooLong: `^About me is too long`,
        },
      },
    }),
    [],
  )

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        await finalizeOnboarding({
          variables: {
            id: me?.profile?.userId,
            userId: me?.profile?.userId,
            [ProfileField.FirstName]: values[ProfileField.FirstName],
            [ProfileField.LastName]: values[ProfileField.LastName],
            [ProfileField.PhotoUrl]: values[ProfileField.PhotoUrl],
            company: values[ProfileField.Organization],
            title: values[ProfileField.JobTitle],
            [ProfileField.LinkedInUrl]: values[ProfileField.LinkedInUrl],
            [ProfileField.About]: values[ProfileField.About],
          },
        })
      } catch (error) {
        let message = _(`error.generic`)

        if (error instanceof Error) {
          message = error.message
        }

        toast.error(`Something went wrong: "${message}"`)
      }
    },
    [me?.profile?.userId, finalizeOnboarding],
  )

  const renderForm = useCallback(
    ({ handleSubmit, form }: FormRenderProps<IProfileValues>) => {
      return (
        <>
          <Profile>
            <AvatarInputField
              entityId={me?.userId}
              entityText={firstNameInitial}
              name={ProfileField.PhotoUrl}
              uploadType={UPLOAD_TYPES.profilePhoto}
            />

            <InputField
              clearable
              label="First Name"
              name={ProfileField.FirstName}
              placeholder="First Name"
              required
            />

            <InputField
              clearable
              label="Last Name"
              name={ProfileField.LastName}
              placeholder="Last Name"
              required
            />

            <InputField
              clearable
              label="Company"
              name={ProfileField.Organization}
              placeholder="Company"
            />

            <InputField
              clearable
              label="Title"
              name={ProfileField.JobTitle}
              placeholder="Title"
            />

            <InputField
              label="About Me"
              name={ProfileField.About}
              placeholder="Share your story, passions, background, goals, or anything else about you."
              small
              textArea
            />
          </Profile>
          <Action step={1}>
            <Button
              disabled={false}
              mt={5}
              type="button"
              onClick={async () => {
                await handleSubmit()

                if (refetchMe) {
                  await refetchMe()
                }

                if (!form.getState().hasValidationErrors) {
                  navigate(ROUTES.ONBOARDING_CONNECT)
                }
              }}
            >
              Continue
            </Button>
          </Action>
        </>
      )
    },
    [me?.userId, firstNameInitial, navigate, refetchMe],
  )

  return (
    <Form<IProfileValues>
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, formConstraints)}
      onSubmit={onSubmit}
    />
  )
}
