/* eslint-disable class-methods-use-this */
import { IGraphTagNode } from 'Features/GraphNodes/NodeTypes'
import Regraph from 'regraph'

import { COMBO_NAME, NODE_NAME, NodeKind, SkillTagKind } from 'Constants/graph'

import { theme } from 'Theme'

import { BaseGraphNode } from './BaseGraphNode'

import { IGraphNodeEdgeEvent } from '../IRegraphContext'

export class TagNode extends BaseGraphNode {
  public item: Regraph.Node

  public entity: IGraphTagNode

  constructor({ tag }: { tag: IGraphTagNode }) {
    super(tag.id!)
    this.entity = tag

    this.data = {
      id: tag.id,
      type: tag.kind,
      cluster: `${tag.kind}_${COMBO_NAME[tag.kind! as SkillTagKind]}`,
    }

    this.item = this.build()
  }

  onDoubleClick(args?: IGraphNodeEdgeEvent): Regraph.Node {
    super.onDoubleClick()

    if (args?.handleLoadPeople && args?.communityIds) {
      args?.handleLoadPeople?.({
        tagIds: [this.entity.id!],
        communityIds: args.communityIds,
      })
    }

    return this.item
  }

  onItemIteraction(args?: IGraphNodeEdgeEvent): Regraph.Node {
    super.onItemIteraction()

    // Get the current size of the selected item, so we can add modifiers to it
    let size = args?.event?.item?.size
    if (args?.event.selected) {
      const isSelectedNodeSizeMultiplier = 0.75
      size += isSelectedNodeSizeMultiplier
    }

    const glyphs = this.item?.glyphs ?? []

    glyphs.push({
      image: '/img/quick-connector.svg',
      radius: 29,
      angle: 180,
      size: 1,
    })

    const label = {
      ...this.item?.label,
      margin: {
        top: 95,
      },
    }

    return {
      ...this,
      size,
      glyphs,
      halos: [
        {
          color: '#27af8b',
          radius: 28,
          width: 3,
        },
        {
          color: '#1ccda4',
          radius: 32,
          width: 6,
        },
      ],
      label,
    } as Regraph.Node
  }

  build(): Regraph.Node {
    const borderWidth = 1.5
    const borderToTextGap = 8
    const DEFAULT_NODE_SIZE = 50
    const isSelected = false
    const text = super.addLineBreak(this.entity.name)

    return {
      data: this.data,
      color: isSelected ? '#ffffff' : '#F9FAFB',
      shape: 'circle',
      size: isSelected ? 1.75 : 1,
      border: {
        color: isSelected
          ? this.nodeKindColor(this.entity.kind! as NodeKind)
          : 'transparent',
        width: borderWidth,
      },
      image: this.nodeImage(this.entity.kind! as NodeKind),
      label: [
        {
          backgroundColor: 'transparent',
          margin: {
            top: DEFAULT_NODE_SIZE + borderWidth * 2 + borderToTextGap,
            bottom: 0,
            left: 0,
            right: 0,
          },
          color: '#2D2D2D',
          fontFamily: 'Inter',
          fontSize: 14,
          bold: true,
          text,
          position: {
            horizontal: 'center',
            vertical: 'top',
          },
        },
        {
          backgroundColor: 'transparent',
          color: '#757575',
          fontFamily: 'Inter',
          fontSize: 12,
          text: NODE_NAME[this.entity.kind! as SkillTagKind],
        },
      ],
    }
  }

  nodeKindColor(kind: NodeKind, defaultColor?: string) {
    return theme.graph.nodeKind[kind] || defaultColor
  }

  nodeKindLightColor(kind: NodeKind) {
    return theme.graph.nodeKindLight[kind] || '#dfdfdf'
  }

  nodeImage(kind: NodeKind) {
    return `/img/nodes/${kind}.svg`
  }
}
