import { IGraphSkillNode } from 'Features/GraphNodes/NodeTypes'
import Regraph from 'regraph'

import { ItemType } from 'Constants/graph'

import { BaseGraphNode } from './BaseGraphNode'

import { IGraphNodeEdgeEvent } from '../IRegraphContext'

export class SkillNode extends BaseGraphNode {
  public item: Regraph.Node

  public entity: IGraphSkillNode

  constructor({ skill }: { skill: any }) {
    super(skill.id)
    this.entity = skill
    this.data = {
      id: skill?.id,
      type: ItemType.Skill,
      cluster: `skill`,
    }

    this.item = this.build()
  }

  onDoubleClick(args?: IGraphNodeEdgeEvent): Regraph.Node {
    super.onDoubleClick()

    if (args?.handleLoadPeople && args?.communityIds) {
      args?.handleLoadPeople?.({
        skillIds: [this.entity.id!],
        communityIds: args.communityIds,
      })
    }

    return this.item
  }

  onItemIteraction(args?: IGraphNodeEdgeEvent): Regraph.Node {
    super.onItemIteraction()

    // Get the current size of the selected item, so we can add modifiers to it
    let size = args?.event?.item?.size
    if (args?.event.selected) {
      const isSelectedNodeSizeMultiplier = 0.75
      size += isSelectedNodeSizeMultiplier
    }

    const glyphs = this.item?.glyphs ?? []

    glyphs.push({
      image: '/img/quick-connector.svg',
      radius: 29,
      angle: 180,
      size: 1,
    })

    const label = {
      ...this.item?.label,
      margin: {
        top: 95,
      },
    }

    return {
      ...this,
      size,
      glyphs,
      halos: [
        {
          color: '#27af8b',
          radius: 28,
          width: 3,
        },
        {
          color: '#1ccda4',
          radius: 32,
          width: 6,
        },
      ],
      label,
    } as Regraph.Node
  }

  private build(): Regraph.Node {
    const borderWidth = 1.5
    const borderToTextGap = 8
    const nodeSize = 50
    const text = super.addLineBreak(this.entity.name)

    return {
      data: this.data,
      color: '#F9FAFB',
      shape: 'circle',
      size: 1,
      border: {
        color: 'transparent',
        width: borderWidth,
      },
      image: `/img/nodes/skill.svg`,
      label: [
        {
          backgroundColor: 'transparent',
          margin: {
            top: nodeSize + borderWidth * 2 + borderToTextGap,
            bottom: 0,
            left: 0,
            right: 0,
          },
          color: '#2D2D2D',
          fontFamily: 'Inter',
          fontSize: 14,
          bold: true,
          text,
          maxWidth: 200,
          position: {
            horizontal: 'center',
            vertical: 'top',
          },
        },
        {
          backgroundColor: 'transparent',
          color: '#757575',
          fontFamily: 'Inter',
          fontSize: 12,
          text: 'Skill',
        },
      ],
    }
  }
}
