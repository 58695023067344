import loadable from '@loadable/component'

import ComingSoon from './ComingSoon'
import NotFound from './NotFound'
import Redirector from './Redirector'

const App = {
  Notes: loadable(
    () => import('./Notes') /* webpackChunkName: "app-community-notes" */,
  ),
  Dashboard: loadable(
    () =>
      import('./Dashboard' /* webpackChunkName: "app-community-dashboard" */),
  ),
  DashboardUsers: loadable(
    () =>
      import(
        './Dashboard/Users' /* webpackChunkName: "app-community-dashboard-users" */
      ),
  ),
  Communities: loadable(
    () =>
      import(
        './Communities'
      ) /* webpackChunkName: "app-community-communities" */,
  ),
  HubSpotOAuth: loadable(() => import('./HubSpot/OAuth')),
  Management: loadable(
    () =>
      import('./Management') /* webpackChunkName: "app-community-management" */,
  ),
  ComingSoon,
  Redirector,
  NotFound,
}

export default App
