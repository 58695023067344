import { Chart } from 'regraph'

import { AskOfferStatementKind } from 'Constants/mainGraphQL'

import { isUserNode } from './isUserNode'

import { ASK_GLYPH_PATH } from '../constants'
import { BaseGraphNode, IGraphNode } from '../Nodes/BaseGraphNode'

export const isGlyphAskOffer = (
  node: IGraphNode | null,
  subItem: Chart.SubItem | null,
) => {
  if (!isGlyphOfType(node, subItem, ASK_GLYPH_PATH)) return false

  const userData = (node as BaseGraphNode).entity
  if (!userData?.askOfferStatements || !userData.communityUserId) return false

  const communityUsersAsks = userData?.askOfferStatements.filter(
    (ask: MainSchema.GraphAskOfferType) =>
      ask.kind === AskOfferStatementKind.Ask,
  )
  if (!communityUsersAsks?.length) return false

  return !!communityUsersAsks
}

export const isGlyphOfType = (
  node: IGraphNode | null,
  subItem: Chart.SubItem | null,
  glyphImage: string,
) => {
  if (!node || !subItem || subItem.type !== 'glyph') return false
  if (!('data' in node) || !isUserNode(node) || !node.data) return false

  const glyphIndex = node?.item?.glyphs?.findIndex(
    (glyph: any) => glyph?.image === glyphImage,
  )
  return glyphIndex !== -1 && subItem.index === glyphIndex
}
