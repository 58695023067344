import React from 'react'

import { IconArrowBack, IconChartDots3 } from '@tabler/icons-react'
import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import NewNetworkMagicContext from 'Features/Graph/Components/GraphContentMenu/NetworkMagicContext'
import { useRegraphContext } from 'Features/Graph/useRegraphContext'
import useRegraphHandlers from 'Features/Graph/useRegraphHandlers'
import GraphSnapshotContextMenu from 'Features/GraphSnapshot/GraphSnapshotContextMenu'
import Utils from 'Utils'

import CommunitySelector from 'Components/Blocks/CommunitySelector'
import { NetworkMagicContext } from 'Components/Blocks/Graph/ContextMenu/index'
import useControlHandlers from 'Components/Blocks/Graph/GraphControls/useControlHandlers'
import SidebarUser from 'Components/Blocks/SidebarUser'
import { Link, Row, Tooltip } from 'Components/UI'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import { useCommunity, useGraphContext, usePermission } from 'Hooks'

import { COMMUNITY_DASHBOARD } from 'Router/routes'

import _ from 'Services/I18n'

import CreateNewButton from './CreateNewButton/CreateNewButton'
import {
  IconButtonStyled,
  Logo,
  LogoNavWrapper,
  LogoWrapper,
  Menu,
  NavDivider,
} from './styles'

export interface IHeader {
  menuHidden?: boolean
  restricted?: boolean
  // onOpenInvitePanel?: () => void
  // onOpenNotifications?: () => void
  // notificationsButtonRef?: MutableRefObject<HTMLButtonElement | null>
  // invitationsButtonRef?: MutableRefObject<HTMLButtonElement | null>
  isDashboard?: boolean
}

const Header = ({
  menuHidden = false,
  restricted = false,
  isDashboard = false,
}: IHeader) => {
  const { community } = useCommunity()
  const { can } = usePermission()
  const {
    isLoading: isGraphLoading,
    subGraphs,
    graphControls,
  } = useGraphContext()

  const {
    setShowNetworkMenu,
    setShowGraphSnapshotsMenu,
    showNetworkMenu,
    showGraphSnapshotsMenu,
    contextNetworkMenuRef,
    contextGraphSnapshotsMenuRef,
    handleExitSubGraph,
    handleResetGraph,
    handleLayoutGraph,
    handleShowNetwork,
    handleShowAllTags,
    handleShowAllUsers,
    handleSetAnalyzer,
    handleSetLayout,
  } = useControlHandlers()

  const { handleExitingSubGraph } = useRegraphHandlers()

  const { subGraph } = useRegraphContext()

  const { isFeatureEnabled } = useFeatureFlag(['regraph'])

  const communityDashboardPath = Utils.URL.generatePath(COMMUNITY_DASHBOARD, {
    slug: community?.slug,
  })

  const canCreateUser =
    can(
      PERMISSION_ACTION.CREATE,
      PERMISSION_SUBJECT.ACCOUNT,
      PERMISSION_SCOPES.ACCOUNT_OWN_CREATOR,
    ) && !menuHidden

  // TODO: Enable this when we have a use for it
  // const canViewInvites = can(
  //   PERMISSION_ACTION.READ,
  //   PERMISSION_SUBJECT.INVITE,
  //   [],
  // )

  return (
    <Menu isDashboard={isDashboard}>
      <Row center fullWidth>
        <LogoNavWrapper>
          <LogoLink restricted={restricted} to={communityDashboardPath} />
          {!menuHidden && community && (
            <>
              <NavDivider />
              <CommunitySelector />
            </>
          )}
        </LogoNavWrapper>

        <Row center gap="10px" ml="auto" noShrink>
          {!isGraphLoading && (
            <>
              {(!!subGraphs.length || !!subGraph.length) && (
                <Tooltip content="Exit Subgraph">
                  <IconButtonStyled
                    tertiary
                    onClick={
                      isFeatureEnabled('regraph')
                        ? () => handleExitingSubGraph()
                        : handleExitSubGraph
                    }
                  >
                    <IconArrowBack />
                  </IconButtonStyled>
                </Tooltip>
              )}
              {graphControls?.graphSnapshots && (
                <LogoNavWrapper>
                  <GraphSnapshotContextMenu
                    isOpen={showGraphSnapshotsMenu}
                    left="auto"
                    ref={contextGraphSnapshotsMenuRef}
                    right={0}
                    setShowGraphSnapshotsMenu={setShowGraphSnapshotsMenu}
                    top="auto"
                  />
                </LogoNavWrapper>
              )}

              {graphControls?.myNetwork && graphControls?.reset && (
                <div style={{ position: 'relative' }}>
                  <Tooltip content={_('tips.networkOptions')}>
                    <IconButtonStyled
                      tertiary
                      onClick={() => setShowNetworkMenu(true)}
                    >
                      <IconChartDots3 />
                    </IconButtonStyled>
                  </Tooltip>

                  {isFeatureEnabled('regraph') && (
                    <NewNetworkMagicContext
                      isOpen={showNetworkMenu}
                      left="auto"
                      ref={contextNetworkMenuRef}
                      right={0}
                      top="auto"
                    />
                  )}

                  {!isFeatureEnabled('regraph') && (
                    <NetworkMagicContext
                      isOpen={showNetworkMenu}
                      left="auto"
                      ref={contextNetworkMenuRef}
                      right={0}
                      top="auto"
                      onLayoutGraph={handleLayoutGraph}
                      onResetGraph={handleResetGraph}
                      onSetAnalyzer={handleSetAnalyzer}
                      onSetLayout={handleSetLayout}
                      onShowAllTags={handleShowAllTags}
                      onShowAllUsers={handleShowAllUsers}
                      onShowNetwork={handleShowNetwork}
                    />
                  )}
                </div>
              )}
            </>
          )}

          {canCreateUser && <CreateNewButton />}

          {/* TODO: Enable these when we have a use for them */}
          {/* {!restricted && !menuHidden && (
            <NotificationsButton
              ref={notificationsButtonRef}
              onOpen={onOpenNotifications}
            />
          )}

          {canViewInvites && !restricted && (
            <InviteManagementButton
              ref={invitationsButtonRef}
              onOpenInvitePanel={onOpenInvitePanel}
            />
          )} */}
          <SidebarUser />
        </Row>
      </Row>
    </Menu>
  )
}

const LogoLink = ({ to, restricted }: { to: string; restricted: boolean }) =>
  restricted ? (
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
  ) : (
    <Link to={to}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
    </Link>
  )

export default Header
