import { createContext } from 'react'

import {
  ApolloQueryResult,
  OperationVariables,
  QueryResult,
} from '@apollo/client'

export interface IAppContext {
  me?: MainSchema.User
  refetchMe?: QueryResult<
    MainQueryData<'me'>,
    {
      communityId?: string
    }
  >['refetch']
  loadingMe?: boolean
  refetchCommunityBySlug?: <
    TVariables extends OperationVariables = OperationVariables,
  >(
    variables?: Partial<TVariables>,
  ) => Promise<ApolloQueryResult<any>>
  slug?: string | null
  // TODO: replace any type
  locale?: any
  isSuperAdmin?: boolean
  isRestricted?: boolean
  canShowDashboard?: boolean
  loadingCommunityUser?: boolean
}

export default createContext<IAppContext>({})
