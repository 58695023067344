import React, { useCallback, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation } from '@apollo/client'
import createGraphSnapshotMutation from 'GraphQL/Mutations/GraphSnapshot/createGraphSnapshot.graphql'
import graphSnapshotsQuery from 'GraphQL/Queries/GraphSnapshot/graphSnapshots.graphql'
import validate from 'validate.js'

import { Column, InputField, Modal } from 'Components/UI'

import { useCommunityContext, useGraphContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { CONTEXT_GRAPH_SNAPSHOTS_FIELD } from '../GraphSnapshotContextMenu'
import utils from '../utils'

interface CreateGraphSnapshotModalProps {
  isOpen?: boolean
  onClose?: (success: boolean) => void
}

enum CreateGraphSnapshotFormField {
  Name = 'name',
}

interface CreateGraphSnapshotFormValues {
  [CreateGraphSnapshotFormField.Name]: string
}

const CreateGraphSnapshotModal: React.FC<CreateGraphSnapshotModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { community } = useCommunityContext()
  const graphContext = useGraphContext()
  const t = useScopedI18n('features.graphSnapshot')

  const createGraphSnapshotFormConstraints: Record<
    CreateGraphSnapshotFormField,
    any
  > = {
    [CreateGraphSnapshotFormField.Name]: {
      type: 'string',
      presence: {
        allowEmpty: false,
        message: `^${t('form.name.validation.required')}`,
      },
      length: {
        maximum: 255,
        tooLong: `^${t('form.name.validation.tooLong')}`,
      },
    },
  }

  const [createGraphSnapshot] = useMutation<
    Pick<MainSchema.Mutation, 'createGraphSnapshot'>,
    MainSchema.MutationCreateGraphSnapshotArgs
  >(createGraphSnapshotMutation)

  const [isLoading, setIsLoading] = useState(false)

  const submit = useCallback(
    async (values: CreateGraphSnapshotFormValues) => {
      if (!community) {
        throw new Error('community is not set')
      }

      setIsLoading(true)

      try {
        const state = utils.generateGraphSnapshotStateFromGraphState(
          graphContext.graphState,
        )

        const createGraphSnapshotResult = await createGraphSnapshot({
          variables: {
            communityId: community.id,
            name: values[CreateGraphSnapshotFormField.Name],
            state: state.json,
            stateVersion: state.version,
          },
          refetchQueries: [
            {
              query: graphSnapshotsQuery,
              variables: {
                communityId: community.id,
              },
            },
          ],
        })

        graphContext.setCurrentGraphSnapshotId(
          createGraphSnapshotResult.data?.createGraphSnapshot?.id ||
            CONTEXT_GRAPH_SNAPSHOTS_FIELD.RECENTLY_ADDED,
        )
        graphContext.setSavedLoadGraphState(graphContext.loadGraphState)

        toast.success({
          title: t('toast.title'),
          text: t('toast.created'),
        })

        onClose?.(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [community, createGraphSnapshot, onClose, t, graphContext],
  )

  const renderForm = useCallback(
    ({
      handleSubmit,
      valid: isValid,
    }: FormRenderProps<CreateGraphSnapshotFormValues>) => {
      return (
        <Modal
          cancelText={t('form.cancel')}
          confirmDisabled={isLoading || !isValid}
          confirmText={t('form.create')}
          isOpen={isOpen}
          title={t('modal.createGraphSnapshotModal.title')}
          width="456px"
          onClose={() => onClose?.(true)}
          onConfirm={handleSubmit}
        >
          <Column gap={3}>
            <InputField
              checkErrorIfDirty
              label={t('form.name.label')}
              name={CreateGraphSnapshotFormField.Name}
              required
            />
          </Column>
        </Modal>
      )
    },
    [isLoading, isOpen, onClose, t],
  )

  if (!isOpen) return null

  return (
    <Form<CreateGraphSnapshotFormValues>
      render={renderForm}
      validate={values => validate(values, createGraphSnapshotFormConstraints)}
      onSubmit={submit}
    />
  )
}

export default CreateGraphSnapshotModal
