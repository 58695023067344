/* eslint-disable class-methods-use-this */
import Regraph from 'regraph'

import { IGraphNodeEdgeEvent } from '../IRegraphContext'

export interface IGraphEdge {
  id: string
  data: any
  item: Regraph.Link | undefined
  onClick: (args?: IGraphNodeEdgeEvent) => Regraph.Link
  onDoubleClick: (args?: IGraphNodeEdgeEvent) => Regraph.Link
  onItemIteraction: (args?: IGraphNodeEdgeEvent) => Regraph.Link
  onChange: (args?: IGraphNodeEdgeEvent) => Regraph.Link
  onHover: (args?: IGraphNodeEdgeEvent) => Regraph.Link
  onDragStart: (args?: IGraphNodeEdgeEvent) => Regraph.Link
  onDragOver: (args?: IGraphNodeEdgeEvent) => Regraph.Link
  onDragEnd: (args?: IGraphNodeEdgeEvent) => Regraph.Link
}

export abstract class BaseGraphEdge implements IGraphEdge {
  id: string

  id1: string

  id2: string

  data: any

  abstract item: Regraph.Link | undefined

  constructor(id1: string, id2: string) {
    // This was done this way originally to reduce the amount of edges on the graph
    // Leaving it as is for now.
    const [_id1, _id2] = [id1, id2].sort()
    this.id = `edge:${_id1}:${_id2}`
    this.id1 = _id1
    this.id2 = _id2
  }

  onClick(): Regraph.Link {
    return this.item as Regraph.Link
  }

  onDoubleClick(): Regraph.Link {
    return this.item as Regraph.Link
  }

  onItemIteraction(): Regraph.Link {
    return this.item as Regraph.Link
  }

  onChange(): Regraph.Link {
    return this.item as Regraph.Link
  }

  onHover(): Regraph.Link {
    return this.item as Regraph.Link
  }

  onDragStart(): Regraph.Link {
    return this.item as Regraph.Link
  }

  onDragOver(): Regraph.Link {
    return this.item as Regraph.Link
  }

  onDragEnd(): Regraph.Link {
    return this.item as Regraph.Link
  }

  static DEFAULT_EDGE_SIZE = 0.6
}
