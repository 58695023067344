import React, { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'

import forEach from 'lodash/forEach'

import CheckIfFeatureFlagEnabledQuery from './Queries/CheckIfFeatureFlagEnabled.graphql'

export const useFeatureFlag = (keys?: string[]) => {
  const [enabledFeatures, setEnabledFeatures] = React.useState<
    Record<string, boolean>
  >({})
  const [checkFeatureFlag] = useLazyQuery<{
    checkIfFeatureFlagEnabled: boolean
  }>(CheckIfFeatureFlagEnabledQuery, { fetchPolicy: 'network-only' })

  const featureEnabled = useCallback(
    async (key: string): Promise<boolean> => {
      try {
        const result = await checkFeatureFlag({ variables: { key } })
        return result.data?.checkIfFeatureFlagEnabled ?? false
      } catch {
        return false
      }
    },
    [checkFeatureFlag],
  )

  React.useEffect(() => {
    async function checkRegraphFeature(key: string) {
      const enabled = { [key]: await featureEnabled(key) }
      setEnabledFeatures(prevState => ({
        ...prevState,
        ...enabled,
      }))
    }

    forEach(keys, key => {
      checkRegraphFeature(key)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureEnabled])

  function isFeatureEnabled(key: string) {
    return enabledFeatures?.[key] || false
  }

  return { featureEnabled, isFeatureEnabled }
}
