import { ApolloClient } from '@apollo/client'

import getPinnedNodesQuery from './Queries/getPinnedNodes.graphql'

export interface IPinnedNode {
  entityId: string
  entityKind: MainSchema.PinnedNodeKind
  creatorUserId: string
  entity: MainSchema.PinnedNodeEntityType
}

export interface IPinnedNodesResult {
  pinnedNodes: IPinnedNode[]
}

export interface IPinnedNodesArgs {
  client?: ApolloClient<any>
}

export async function LoadPinnedNodes(args: IPinnedNodesArgs) {
  const pinnedNodes = await args.client?.query<
    Pick<MainSchema.Query, 'getPinnedNodes'>
  >({
    fetchPolicy: 'network-only',
    query: getPinnedNodesQuery,
  })

  return pinnedNodes
}
