import { MouseEventHandler } from 'react'

import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface ITagMouseEvent
  extends React.MouseEvent<HTMLDivElement | HTMLButtonElement> {
  entity?: any
}

export interface IContainer extends MarginProps, LayoutProps {
  onClick?: MouseEventHandler<HTMLDivElement>
  colorKind: string
  small?: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${mapToTheme('tags.height')}px;
  width: max-content;
  gap: ${themeGet('space.1')}px;
  padding-inline: ${themeGet('space.3')}px;
  border-radius: ${mapToTheme('tags.borderRadius')}px;
  background-color: ${props => themeGet(`colors.tag.${props.colorKind}.bg`)};
  border: 1px solid ${props => themeGet(`colors.tag.${props.colorKind}.border`)};
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
  cursor: ${props => (props.onClick ? 'pointer' : 'initial')};

  & * {
    color: ${props => themeGet(`colors.tag.${props.colorKind}.color`)};
  }

  & svg {
    flex-shrink: 0;
    height: ${mapToTheme('tags.iconSize')}px;
    width: ${mapToTheme('tags.iconSize')}px;
  }

  ${margin}
  ${layout}
`

export const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
`
