import React, { useCallback } from 'react'

import { useQuery } from '@apollo/client'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import graphSnapshotsQuery from 'GraphQL/Queries/GraphSnapshot/graphSnapshots.graphql'
import { DateTime } from 'luxon'

import { Box, Column, Modal, Row, Tooltip } from 'Components/UI'
import { Link, Text } from 'Components/UI/_v2'

import { useCommunityContext } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

interface ManageGraphSnapshotModalProps {
  isOpen?: boolean
  onClose?: (success: boolean) => void
}

const ManageGraphSnapshotModal: React.FC<ManageGraphSnapshotModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { community } = useCommunityContext()
  const t = useScopedI18n('features.graphSnapshot')

  const { data: graphSnapshotsData } = useQuery<
    Pick<MainSchema.Query, 'graphSnapshots'>,
    MainSchema.QueryGraphSnapshotsArgs
  >(graphSnapshotsQuery, {
    variables: community
      ? {
          communityId: community.id,
        }
      : undefined,
    skip: !community,
    fetchPolicy: 'network-only',
  })
  const graphSnapshots = graphSnapshotsData?.graphSnapshots ?? []

  const handleEdit = useCallback((graphSnapshot: MainSchema.GraphSnapshot) => {
    EventBus.trigger(
      EventBus.actions.dashboard.updateGraphSnapshot,
      graphSnapshot,
    )
  }, [])

  const handleDelete = useCallback(
    (graphSnapshot: MainSchema.GraphSnapshot) => {
      EventBus.trigger(
        EventBus.actions.dashboard.deleteGraphSnapshot,
        graphSnapshot,
      )
    },
    [],
  )

  if (!isOpen) return null

  return (
    <Modal
      confirmText={t('form.done')}
      isOpen={isOpen}
      title={t('modal.manageGraphSnapshotsModal.title')}
      width="456px"
      onClose={() => onClose?.(true)}
      onConfirm={() => onClose?.(true)}
    >
      <Box maxHeight={'300px'} overflowY={'auto'}>
        <Column gap={2}>
          {graphSnapshots.length === 0 && (
            <Text
              color={colors.text.primary}
              textAlign={'center'}
              variant="body-s"
            >
              {t('modal.manageGraphSnapshotsModal.noSavedGraphs')}
            </Text>
          )}
          {graphSnapshots.map(graphSnapshot => (
            <Box
              borderColor={'#DDE1E4'}
              borderRadius={'4px'}
              borderStyle={'solid'}
              borderWidth={'1px'}
              key={graphSnapshot.id}
              px={3}
              py={2}
            >
              <Row
                alignItems={'center'}
                gap={2}
                justifyContent={'space-between'}
              >
                <Column gap={1}>
                  <Text color={colors.text.primary} variant="body-s">
                    {graphSnapshot.name}
                  </Text>

                  <Row>
                    <Text color={colors.text.primary} variant="body-xs">
                      {t('modal.manageGraphSnapshotsModal.lastUpdated', {
                        lastUpdatedAt: DateTime.fromISO(
                          graphSnapshot.updatedAt,
                        ).toLocaleString(DateTime.DATETIME_SHORT),
                      })}
                    </Text>
                  </Row>
                </Column>

                <Row gap={2}>
                  <Link
                    title={t('form.edit')}
                    variant="secondary"
                    onClick={() => handleEdit(graphSnapshot)}
                  >
                    <Tooltip content={t('form.edit')}>
                      <IconPencil size={20} />
                    </Tooltip>
                  </Link>

                  <Link
                    title={t('form.delete')}
                    variant="secondary"
                    onClick={() => handleDelete(graphSnapshot)}
                  >
                    <Tooltip content={t('form.delete')}>
                      <IconTrash size={20} />
                    </Tooltip>
                  </Link>
                </Row>
              </Row>
            </Box>
          ))}
        </Column>
      </Box>
    </Modal>
  )
}

export default ManageGraphSnapshotModal
