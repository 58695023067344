/* eslint-disable class-methods-use-this */
import { IGraphOrganizationNode } from 'Features/GraphNodes/NodeTypes'
import Regraph from 'regraph'

import first from 'lodash/first'
import sortBy from 'lodash/sortBy'

import { ItemType, NODE_KIND, NodeKind } from 'Constants/graph'

import { theme } from 'Theme'

import { BaseGraphNode } from './BaseGraphNode'

import { PanelType } from '../constants'
import { IGraphNodeEdgeEvent } from '../IRegraphContext'

export class OrganizationNode extends BaseGraphNode {
  public item: Regraph.Node

  public entity: IGraphOrganizationNode

  constructor({ organization }: { organization: IGraphOrganizationNode }) {
    super(organization.id!)
    this.entity = organization
    this.data = {
      id: organization?.id,
      type: ItemType.Organization,
      cluster: `organization_`,
    }

    this.item = this.build()
  }

  onClick(args?: IGraphNodeEdgeEvent): Regraph.Node {
    args?.context?.setActivePanel({
      type: PanelType.Organization,
      data: this.entity as IGraphOrganizationNode,
    })

    return this.item
  }

  onDoubleClick(args?: IGraphNodeEdgeEvent): Regraph.Node {
    super.onDoubleClick()

    if (args?.handleLoadPeople && args?.communityIds) {
      args?.handleLoadPeople?.({
        workHistoryOrganizationIds: [this.entity.id!],
        communityIds: args.communityIds,
      })
    }

    return this.item
  }

  onItemIteraction(args?: IGraphNodeEdgeEvent): Regraph.Node {
    super.onItemIteraction()

    // Get the current size of the selected item, so we can add modifiers to it
    let size = args?.event?.item?.size
    if (args?.event.selected) {
      const isSelectedNodeSizeMultiplier = 0.75
      size += isSelectedNodeSizeMultiplier
    }

    const glyphs = this.item?.glyphs ?? []

    glyphs.push({
      image: '/img/quick-connector.svg',
      radius: 29,
      angle: 180,
      size: 1,
    })

    const label = {
      ...this.item?.label,
      margin: {
        top: 95,
      },
    }

    return {
      ...this,
      size,
      glyphs,
      halos: [
        {
          color: '#27af8b',
          radius: 28,
          width: 3,
        },
        {
          color: '#1ccda4',
          radius: 32,
          width: 6,
        },
      ],
      label,
    } as Regraph.Node
  }

  private build(): Regraph.Node {
    const borderWidth = 1.5
    const borderToTextGap = 8
    const logoUrl = this.getOrganizationLogoUrl(this?.entity?.logos)
    const text = super.addLineBreak(this.entity.name)

    return {
      data: this.data,
      color: '#F9FAFB',
      shape: 'circle',
      cutout: true,
      border: {
        width: borderWidth,
      },
      image: logoUrl ?? this.nodeImage(NODE_KIND.organization),
      label: [
        {
          backgroundColor: 'transparent',
          margin: {
            top:
              BaseGraphNode.DEFAULT_NODE_SIZE +
              borderWidth * 2 +
              borderToTextGap,
            bottom: 0,
            left: 0,
            right: 0,
          },
          color: '#2D2D2D',
          fontFamily: 'Inter',
          fontSize: 14,
          bold: true,
          text,
          position: {
            horizontal: 'center',
            vertical: 'top',
          },
        },
        {
          backgroundColor: 'transparent',
          color: '#757575',
          fontFamily: 'Inter',
          fontSize: 12,
          text: 'Organization',
        },
      ],
    }
  }

  getOrganizationLogoUrl(
    organizationLogos?: MainSchema.OrganizationLogo[],
  ): string | null {
    if (!organizationLogos || organizationLogos.length === 0) {
      return null
    }

    const sortedLogosByWidth = sortBy(
      organizationLogos ?? [],
      logo => logo.width,
      'asc',
    )

    return first(sortedLogosByWidth)?.url ?? null
  }

  nodeKindColor(kind: NodeKind, defaultColor?: string) {
    return theme.graph.nodeKind[kind] || defaultColor
  }

  nodeKindLightColor(kind: NodeKind) {
    return theme.graph.nodeKindLight[kind] || '#dfdfdf'
  }

  nodeImage(kind: NodeKind) {
    return `/img/nodes/${kind}.svg`
  }
}
