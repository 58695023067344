/* eslint-disable class-methods-use-this */
import Regraph from 'regraph'
import { resizeImageUrl } from 'Utils/Strings'

import forEach from 'lodash/forEach'

import { S3_URLS } from 'Config'

import { IGraphNodeEdgeEvent } from '../IRegraphContext'

export interface IGraphNode {
  id: string
  entity: any
  item: Regraph.Item | Regraph.Node | Regraph.Link | undefined
  onClick: (args?: IGraphNodeEdgeEvent) => Regraph.Node
  onDoubleClick: (args?: IGraphNodeEdgeEvent) => Regraph.Node
  onItemIteraction: (args?: IGraphNodeEdgeEvent) => Regraph.Node
  onChange: (args?: IGraphNodeEdgeEvent) => Regraph.Node
  onHover: (args?: IGraphNodeEdgeEvent) => Regraph.Node
  onDragStart: (args?: IGraphNodeEdgeEvent) => Regraph.Node
  onDragOver: (args?: IGraphNodeEdgeEvent) => Regraph.Node
  onDragEnd: (args?: IGraphNodeEdgeEvent) => Regraph.Node

  replaceImage(photoUrl: string): string | undefined
  getUserLabel(user: {
    firstName?: string
    lastName?: string
    email?: string
  }): string
  addLineBreak(
    text?: string,
    maxSize?: number,
    delimiter?: string,
  ): string | undefined
}

export abstract class BaseGraphNode implements IGraphNode {
  id: string

  entity: any

  data: any

  abstract item: Regraph.Item | Regraph.Node | Regraph.Link | undefined

  constructor(id: string) {
    this.id = id
  }

  onClick(): Regraph.Node {
    return this.item as Regraph.Node
  }

  onDoubleClick(): Regraph.Node {
    return this.item as Regraph.Node
  }

  onItemIteraction(): Regraph.Node {
    return this.item as Regraph.Node
  }

  onChange(): Regraph.Node {
    return this.item as Regraph.Node
  }

  onHover(): Regraph.Node {
    return this.item as Regraph.Node
  }

  onDragStart(): Regraph.Node {
    return this.item as Regraph.Node
  }

  onDragOver(): Regraph.Node {
    return this.item as Regraph.Node
  }

  onDragEnd(): Regraph.Node {
    return this.item as Regraph.Node
  }

  replaceImage(photoUrl: string): string | undefined {
    let nextUrl = photoUrl

    forEach(S3_URLS, (value, key) => {
      nextUrl = nextUrl.replace(key, value)
    })

    return resizeImageUrl(nextUrl, '128')
  }

  getUserLabel(user: {
    firstName?: string
    lastName?: string
    email?: string
  }): string {
    return (
      `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim() ||
      user.email ||
      '—'
    )
  }

  addLineBreak(
    text?: string,
    maxSize: number = 50,
    delimiter: string = ' ',
  ): string | undefined {
    if (!text || text.length <= maxSize) {
      return text
    }

    // Try to break at the last occurrence of the delimiter before maxSize
    const lastDelimiterIndex = text.lastIndexOf(delimiter, maxSize)

    if (lastDelimiterIndex !== -1) {
      return `${text.slice(0, lastDelimiterIndex)}\n${text.slice(lastDelimiterIndex + 1)}`
    }

    // If no delimiter is found, break within a word and add a hyphen
    return `${text.slice(0, maxSize - 1)}-\n${text.slice(maxSize - 1)}`
  }

  static DEFAULT_NODE_SIZE = 50
}
