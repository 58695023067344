import React, { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'
import deleteGraphSnapshotMutation from 'GraphQL/Mutations/GraphSnapshot/deleteGraphSnapshot.graphql'
import graphSnapshotsQuery from 'GraphQL/Queries/GraphSnapshot/graphSnapshots.graphql'

import { Modal } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { useCommunityContext, useGraphContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { CONTEXT_GRAPH_SNAPSHOTS_FIELD } from '../GraphSnapshotContextMenu'

interface DeleteGraphSnapshotModalProps {
  graphSnapshot: MainSchema.GraphSnapshot
  isOpen?: boolean
  onClose?: (success: boolean) => void
}

const DeleteGraphSnapshotModal: React.FC<DeleteGraphSnapshotModalProps> = ({
  graphSnapshot,
  isOpen,
  onClose,
}) => {
  const graphContext = useGraphContext()
  const { community } = useCommunityContext()
  const t = useScopedI18n('features.graphSnapshot')

  const [deleteGraphSnapshot] = useMutation<
    Pick<MainSchema.Mutation, 'deleteGraphSnapshot'>,
    MainSchema.MutationDeleteGraphSnapshotArgs
  >(deleteGraphSnapshotMutation)

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(async () => {
    if (!community) {
      throw new Error('community is not set')
    }

    setIsLoading(true)

    try {
      await deleteGraphSnapshot({
        variables: {
          id: graphSnapshot.id,
        },
        refetchQueries: [
          {
            query: graphSnapshotsQuery,
            variables: {
              communityId: community.id,
            },
          },
        ],
      })

      toast.success({
        title: t('toast.title'),
        text: t('toast.deleted'),
      })

      onClose?.(true)
    } catch (error) {
      let message = _('error.generic')

      if (error instanceof Error) {
        message = _(`error.${error.message || 'generic'}`)
      }

      toast.error({
        title: t('toast.title'),
        text: message,
      })
    } finally {
      setIsLoading(false)
    }

    graphContext.setCurrentGraphSnapshotId(
      CONTEXT_GRAPH_SNAPSHOTS_FIELD.RECENTLY_ADDED,
    )
  }, [
    community,
    deleteGraphSnapshot,
    onClose,
    t,
    graphSnapshot.id,
    graphContext,
  ])

  if (!isOpen) return null

  return (
    <Modal
      cancelText={t('form.cancel')}
      confirmDisabled={isLoading}
      confirmText={t('form.delete')}
      isOpen={isOpen}
      title={t('modal.deleteGraphSnapshotModal.title')}
      width="456px"
      onClose={() => onClose?.(true)}
      onConfirm={handleSubmit}
    >
      <Text>{t('modal.deleteGraphSnapshotModal.content')}</Text>
    </Modal>
  )
}

export default DeleteGraphSnapshotModal
