import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { IconCheck, IconMinus } from '@tabler/icons-react'

const iconCss = css`
  color: ${themeGet('colors.checkbox.selected.color')};
  width: ${mapToTheme('checkboxes.size')}px;
  height: ${mapToTheme('checkboxes.size')}px;
`

export const CheckIcon = styled(IconCheck)`
  ${iconCss}
`

export const MinusIcon = styled(IconMinus)`
  ${iconCss}
`

export const CheckboxBorder = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${mapToTheme('checkboxes.borderWidth')}px solid transparent;
  border-radius: ${mapToTheme('checkboxes.borderRadius')}px;
  width: ${mapToTheme('checkboxes.size')}px;
  height: ${mapToTheme('checkboxes.size')}px;
  flex-shrink: 0;
`

export const InvisibleCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  background: none;
  border: none;
  opacity: 0;
  pointer-events: none;
`

export interface IContainerProps extends SpaceProps, LayoutProps {
  danger?: boolean
  bold?: boolean
  rounded?: boolean
  secondary?: boolean
  small?: boolean
}

export const Container = styled.label<IContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  gap: ${themeGet('space.2')}px;
  width: max-content;
  max-width: 100%;

  & > input {
    color: ${themeGet('colors.checkbox.selected.color')};

    :enabled ~ ${CheckboxBorder} {
      cursor: pointer;
    }

    :not(:checked) ~ ${CheckboxBorder} > ${CheckIcon} {
      display: none;
    }

    :enabled:not(:checked) ~ ${CheckboxBorder} {
      border-color: ${themeGet('colors.checkbox.border')};
      background-color: ${themeGet('colors.checkbox.bg')};

      &:hover {
        background-color: ${themeGet('colors.checkbox.hover.bg')};
        border-color: ${themeGet('colors.checkbox.hover.border')};
      }
    }

    :enabled:focus:not(:checked) ~ ${CheckboxBorder} {
      border-color: ${themeGet('colors.checkbox.selected.focus.border')};
      box-shadow: ${themeGet('colors.checkbox.selected.focus.shadow')};
    }

    :enabled:focus:checked ~ ${CheckboxBorder} {
      border-color: ${themeGet('colors.checkbox.selected.focus.border')};
      background-color: ${themeGet('colors.checkbox.selected.focus.bg')};
      box-shadow: ${themeGet('colors.checkbox.selected.focus.shadow')};
    }

    :enabled:checked ~ ${CheckboxBorder} {
      background-color: ${themeGet('colors.checkbox.selected.bg')};
      border-color: ${themeGet('colors.checkbox.selected.border')};

      &:hover {
        border-color: ${themeGet('colors.checkbox.selected.hover.border')};
        background-color: ${themeGet('colors.checkbox.selected.hover.bg')};
      }
    }

    :disabled:not(:checked) ~ ${CheckboxBorder} {
      background-color: ${themeGet('colors.checkbox.disabled.bg')};
      border-color: ${themeGet('colors.checkbox.disabled.border')};
    }

    :disabled:checked ~ ${CheckboxBorder} {
      background-color: ${themeGet('colors.checkbox.selected.disabled.bg')};
      border-color: ${themeGet('colors.checkbox.selected.disabled.border')};
    }
  }

  ${space}
  ${layout}
`
