/* eslint-disable class-methods-use-this */
import Regraph from 'regraph'

import { ItemType } from 'Constants/graph'

import { BaseGraphEdge } from './BaseGraphEdge'

export class WorkHistoryEdge extends BaseGraphEdge {
  public item: Regraph.Link

  constructor({ id1, id2 }: { id1: string; id2: string }) {
    super(id1, id2)
    this.data = {
      type: ItemType.Edge,
    }

    this.item = this.build()
  }

  private build(): Regraph.Link {
    return {
      id1: this.id1,
      id2: this.id2,
      lineStyle: 'solid',
      width: BaseGraphEdge.DEFAULT_EDGE_SIZE,
      color: '#2D2D2D',
      fade: true,
      data: this.data,
    }
  }
}
